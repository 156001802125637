'use client';

import { lazy } from 'react';
import animationLoaderLight from './light-loader.json';

const Lottie = lazy(() => import('lottie-react'));

const LoaderDots = ({ className }: { className?: string }) => {
  if (typeof document === 'undefined') {
    return null;
  }

  return (
    <div className={className}>
      <Lottie animationData={animationLoaderLight} loop />
    </div>
  );
};

export default LoaderDots;
